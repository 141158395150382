import { useRef } from 'react';
import { InfoBoxGrid } from '../Various/InfoBoxGrid';
import { PastEventByYear } from '../Event/PastEventsByYear';
import { Container } from '../UI/Container';
import { useTranslation } from "react-i18next";
import { Event } from '../../../types/Event';
import { Donate } from "../SVG/Donate";
import { Route } from "../SVG/Route";
import dayjs from "dayjs";
import { TopDistanceParticipant } from "../../../types/Participant";


export const EventHolder = ({ upcomingEvents, pastEvents, topDonations, topDistance, topDistanceParticipants }: {
    upcomingEvents: Event[],
    pastEvents: Event[],
    topDonations: Event[],
    topDistance: Event[],
    topDistanceParticipants: TopDistanceParticipant[],
}) => {
    const { t } = useTranslation('events');
    const eventsContainerRef = useRef<HTMLDivElement>(null)
    const upcomingEventInfoboxes = upcomingEvents && upcomingEvents.length > 0 ? upcomingEvents.map(event => ({ event: event })) : [];

    return (
        <>
            <Container>
                <div className="mt-8 pt-12 text-center max-w-2xl mx-auto">
                    <h1 className="text-3xl xl:text-[3.5rem] font-semibold text-center tracking-wider leading-none mb-3 lg:mb-6">
                        {t('event_holder.title')}
                    </h1>
                    <p className="mb-6">
                        {t('event_holder.description')}
                    </p>
                </div>
            </Container>
            <div className={`bg-gray-100 py-6 lg:py-12 mt-4 lg:mt-8`}>
                <Container
                    className={`flex flex-col md:flex-row justify-center items-center lg:items-start gap-4 lg:gap-16 header-font `}>
                    <div className={`text-center lg:px-10 relative`}>
                        <Donate classes="w-[180px] h-[180px] lg:w-[280px] lg:h-[280px] z-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary opacity-10"/>
                        <h2 className="text-xl lg:text-xl relative z-10 font-semibold mb-1">{t('event_holder.top_donations')}</h2>
                        <ul className={`z-10 relative`}>
                            {topDonations.map((event, index) => (
                                <li key={event.slug}
                                    className={`py-2 lg:py-3 flex justify-between gap-3 items-center border border-primary/20 border-t-1 border-b-0 border-l-0 border-r-0 ${index === 0 ? 'border-none' : ''}`}>
                                    <div className={`text-sm max-w-[250px] leading-none text-left`}>
                                        <div className={`text-xs opacity-80`}>{dayjs(event.event_start_date).year()}</div>
                                        <div>{event.org_name}</div>
                                    </div>
                                    <div className={`text-green-500 text-lg font-semibold leading-none whitespace-nowrap`}>
                                        {event.result?.donations && (new Intl.NumberFormat('da-DK', { maximumFractionDigits: 0 }).format(event.result?.donations) || 0)} kr
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={`text-center lg:px-10 relative`}>
                        <Route classes="w-[180px] h-[180px] lg:w-[380px] lg:h-[380px] z-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary/90 opacity-10"/>
                        <h2 className="text-xl lg:text-xl relative z-10 font-semibold mb-1">{t('event_holder.top_distance')}</h2>
                        <ul className={`z-10 relative`}>
                            {topDistance.map((event, index) => (
                                <li key={event.slug}
                                    className={`py-2 lg:py-3 flex justify-between gap-3 items-center border border-t-2 border-b-0 border-l-0 border-r-0 ${index === 0 ? 'border-none' : ''}`}>
                                    <div className={`text-sm max-w-[250px] leading-none text-left`}>
                                        <div className={`text-xs opacity-80`}>{dayjs(event.event_start_date).year()}</div>
                                        <div>{event.org_name || event.name}</div>
                                    </div>
                                    <div className={`text-green-500 text-lg font-semibold leading-none whitespace-nowrap`}>
                                        {event.result?.total_meters && new Intl.NumberFormat('da-DK', {
                                            style: 'unit',
                                            unit: 'kilometer',
                                            maximumFractionDigits: 0
                                        }).format(event.result?.total_meters / 1000)}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Container>

                <Container
                    className={`flex flex-col md:flex-row justify-center items-center lg:items-start gap-4 lg:gap-16 header-font mt-8`}>
                    <div className={`text-center lg:px-10 relative`}>
                        <Route classes="w-[180px] h-[180px] lg:w-[380px] lg:h-[380px] z-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary/90 opacity-10"/>
                        <h2 className="text-xl lg:text-xl relative z-10 font-semibold mb-1">{t('event_holder.top_distance_participant')}</h2>
                        <ul className={`z-10 relative flex flex-col lg:flex-row lg:gap-10`}>
                            {topDistanceParticipants.map((participant, index) => (
                                <li key={participant.slug}
                                    className={`py-2 lg:py-3 flex justify-between gap-3 items-center lg:items-end border border-t-2 border-b-0 border-l-0 border-r-0 ${index === 0 ? 'border-none' : ''}`}>
                                    <div className={`text-sm max-w-[250px] leading-none text-left`}>
                                        <div className={`text-xs opacity-80`}>{dayjs(participant.event_start_date).year()}</div>
                                        <div className={`lg:truncate`} title={participant.org_name || participant.org_name}>Løber fra {participant.org_name || participant.org_name}</div>
                                    </div>
                                    <div className={`text-green-500 text-lg font-semibold leading-none whitespace-nowrap`}>
                                        {participant?.total_meters && new Intl.NumberFormat('da-DK', {
                                            style: 'unit',
                                            unit: 'kilometer',
                                            maximumFractionDigits: 0
                                        }).format(participant?.total_meters / 1000)}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Container>
            </div>
            <Container>
                <div className="mb-20 pb-10 relative z-20 pt-10" ref={eventsContainerRef}>
                    {upcomingEventInfoboxes && upcomingEventInfoboxes.length > 0 ? (
                        <>
                            <h2 className="text-2xl lg:text-4xl font-semibold mb-6 text-center">{t('event_holder.upcoming_events')}</h2>
                            <hr className="max-w-md m-auto mb-8 border-primary-700/30 border-2 rounded-full"/>
                            <div className="mb-[60px]"><InfoBoxGrid infoBoxType="event" items={upcomingEventInfoboxes}/>
                            </div>
                        </>
                    ) : null}

                    {pastEvents ? <PastEventByYear events={pastEvents}/> : null}
                </div>
            </Container>
        </>
    );
}
