import { useMemo } from 'react';
import { Event } from '../../../types/Event';
import { InfoBoxGrid } from '../Various/InfoBoxGrid';

// hack to get around typescript having problems with map
interface IMap<K, V> extends Map<K, V> {
  get(key: K): V;
}

export const PastEventByYear = ({ events }: {
  events: Event[]
}) => {

  const eventsByYear: IMap<string, { event: Event }[]> | null = useMemo(() => {
    if (!events || events.length === 0) return null;
    const byYear = events.reduce((acc, event) => {
      const year = (new Date(event.event_start_date).getFullYear()).toString();
      if (!acc.has(year)) {
        acc.set(year, []);
      }
      acc.get(year).push({ event: event });

      return acc;
    }, new Map());

    const sortedYears: string[] = [...byYear.keys()].sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });

    let sortedByYear: IMap<string, { event: Event }[]> = new Map();
    sortedYears.forEach(year => {
      if (!sortedByYear.has(year)) {
        sortedByYear.set(year, []);
      }
      sortedByYear.get(year).push(...byYear.get(year));
    });

    return sortedByYear;
  }, [events]);

  if (eventsByYear === null) return null;

  return (
    <div>
      {Array.from(eventsByYear.keys()).map(year => {
        return (
          <div key={year} className="mb-8">
            <h2 className="text-4xl lg:text-5xl font-semibold mb-2 text-center">{year}</h2>
            <hr className="mb-6 border-primary-700/30 border-2 rounded-full" />
            <InfoBoxGrid key={`grid-${year}`} items={eventsByYear.get(year)} infoBoxType="event" />
          </div>
        );
      })}
    </div>
  );
}