import { useState, useEffect, useMemo } from 'react';
import { EventHolder } from '../components/Layout/EventHolder';
import { sendRequest } from '../services/http';
import { Loading } from '../components/UI/Loading';
import { TopBar } from '../components/Nav/TopBar';
import dayjs from 'dayjs';
import { buildImageLink } from '../services/assets';

export const EventHolderContainer = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [topDistanceParticipants, setTopDistanceParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const pastEvents = useMemo(() => {
    if (allEvents.length === 0) return [];

    const now = dayjs();
    return allEvents
      .filter(event => now.isAfter(dayjs(event.event_end_date)))
      .sort((a, b) => {
        // sort newest first
        return dayjs(a.event_start_date).isBefore(dayjs(b.event_start_date)) ? 1 : -1;
      })
      .map(event => ({
        ...event,
        img: event.cloudflare_img_uuid ? buildImageLink(event.cloudflare_img_uuid) : event.img,
      }));
  }, [allEvents]);

  const upcomingEvents = useMemo(() => {
    if (allEvents.length === 0) return [];

    const now = dayjs();
    return allEvents
      .filter(event => now.isBefore(dayjs(event.event_end_date)))
      .sort((a, b) => {
        return dayjs(a.event_start_date).isBefore(dayjs(b.event_start_date)) ? -1 : 1;
      });
  }, [allEvents]);

  const eventsWithoutEventGroups = allEvents.filter(event => !event.is_group_event);
  allEvents.forEach(event => {
    if (event.is_group_event && event.events) {
        eventsWithoutEventGroups.push(...event.events);
    }
  });
  const top3Donations = eventsWithoutEventGroups
      .filter(event => event.result && event.result.donations) // Filter events with donations
      .sort((a, b) => (b.result?.donations || 0) - (a.result?.donations || 0)) // Sort by donations (desc)
      .filter(
          (event, index, self) =>
              self.findIndex(e => e.org_name === event.org_name) === index // Ensure unique by org_name
      )
      .slice(0, 3); // Take the top 3

  const top3DistanceRan = eventsWithoutEventGroups
      .filter(event => event.result && event.result.total_meters) // Filter events with distance ran
      .sort((a, b) => (b.result?.total_meters || 0) - (a.result?.total_meters || 0)) // Sort by total_meters (desc)
      .filter(
          (event, index, self) =>
              self.findIndex(e => e.org_name === event.org_name) === index // Ensure unique by org_name
      )
      .slice(0, 3); // Take the top 3

  const top3DistanceParticipants = topDistanceParticipants
      .filter(participant => participant.org_slug !== 'wfw')
      .slice(0, 3);

  useEffect(() => {
    const cb = (response) => {
      // TODO handle errors
      setAllEvents(response.data);
      setIsLoading(false);
    }
    sendRequest({
      method: 'GET',
      endpoint: '/frontpage/list',
      callback: cb
    });

    sendRequest({
      method: 'GET',
      endpoint: '/event/participant-top-distance',
      callback: (response) => setTopDistanceParticipants(response.data)
    });

    return () => setIsLoading(true)
  }, []);

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <TopBar />
      <EventHolder upcomingEvents={upcomingEvents} pastEvents={pastEvents} topDonations={top3Donations} topDistance={top3DistanceRan} topDistanceParticipants={top3DistanceParticipants} />
    </>
  );
}