import { useMemo, useState } from "react";
import { Participant } from "../../../types/Participant";
import { ProfileCardListItem } from "../ProfileCard/ProfileCardListItem";
import { Event } from "../../../types/Event";
import { useNavigate } from "react-router-dom";
import { useParticipants } from "../../hooks/useParticipants";
import { SponsorMode } from "../../variables/enum";
import { useTranslation } from "react-i18next";

export const SearchSponsorParticipants = ({ event, sponsorMode }: {
    event: Event,
    sponsorMode?: SponsorMode,
}) => {
    const { t } = useTranslation(['sponsor']);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { participants } = useParticipants(event);
    const minParticipantsForSearchField = 4;

    const searchResults = useMemo(() => {
        if (participants.length < minParticipantsForSearchField) {
            return participants;
        }
        if (searchTerm.length === 0 || participants.length === 0) {
            return [];
        }
        const results = participants.filter((participant: Participant) => {
            if (participant.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true;
            }
            if (participant.startnr && parseInt(participant.startnr) === parseInt(searchTerm)) {
                return true;
            }
            return false;
        });
        // limit to 5
        if (results.length > 5) {
            return results.slice(0, 2);
        }

        return results;
    }, [searchTerm, participants]);

    const displaySearchField = participants.length > minParticipantsForSearchField;

    const toggledClasses = useMemo(() => {
        if (searchTerm.length > 0) {
            return 'opacity-100 z-10';
        }
        return 'opacity-0 pointer-events-none -z-10';
    }, [searchTerm]);

    return (
        <div className="relative w-full max-w-md m-auto">
            {displaySearchField && (
                <div className="relative">
                    <input
                        type="text" value={searchTerm}
                        placeholder={t('sponsor:sponsor_type_page.find_your_participant_placeholder')}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={`
                        m-auto md:ml-0 md:mr-0 p-5 w-full block rounded pr-16
                        border border-b-2 border-x-gray-300 border-t--gray-300 shadow focus:border-b-primary-500 transition-all
                        focus:ring-0 outline-none text-xl`}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 text-primary absolute right-4 top-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div>
            )}

            <div className={`bg-white shadow rounded p-2 transition-all absolute left-0 right-0 top-full flex flex-col gap-1 ${toggledClasses}`}>
                {searchResults.map((participant) => (
                    <div key={participant.id} onClick={() => sponsorMode === SponsorMode.BUSINESS ? navigate(participant.businessSponsorLink || '') : navigate(participant.sponsorLink || '')}>
                        <ProfileCardListItem startnr={participant.startnr} name={participant.name || ''} sponsorLink={participant.sponsorLink} hideShareBtn={true}/>
                    </div>
                ))}
            </div>

            {!displaySearchField && (
                <div className={`bg-white shadow rounded p-2 flex flex-col gap-1`}>
                    {searchResults.map((participant) => (
                        <div key={participant.id} onClick={() => sponsorMode === SponsorMode.BUSINESS ? navigate(participant.businessSponsorLink || '') : navigate(participant.sponsorLink || '')}>
                            <ProfileCardListItem startnr={participant.startnr} name={participant.name || ''} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}